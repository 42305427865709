.main-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // min-height: 100%;
  text-align: center;
  background: var(
    --BG,
    linear-gradient(12deg, #301007 -1.9%, #842d15 48.71%, #561c0c 83.22%)
  );
  min-height: 100%;

  header {
    flex-shrink: 0;
    padding: 0 20px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-lang {
      background-color: #f6e36d;
      border: 1px solid #000;
      border-radius: 0.2rem;
      color: #000;
      padding: 0.2rem;
    }
    .logo {
      width: 50%;
      // max-width: 150px;
      // margin: 0 auto;
      margin: 0 auto 0 3em;
      // // margin-left: 2em;
      // background: linear-gradient(180deg, #000 18.52%, rgba(0, 0, 0, 0) 97.6%);
      display: none;
    }
    .menu-icon {
      position: absolute;
      //right: 20px;
      transition: transform 0.3s;
      cursor: pointer;
      display: none;

      top: 1em;
      left: 1em;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  footer {
    flex-shrink: 0;
    width: 100%;
    .link {
      color: #2f0743;
      font-family: Myriad Pro;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      text-transform: uppercase;
      cursor: pointer;
      margin: 0 auto;
    }
  }
  .content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-bottom: 15%;
  }
}
.banner {
  width: 100%;
  box-shadow: 0px 3px 45px 0px #121212;

  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.banner1 {
  width: 100%;
}
.banner-text {
  color: white;
  position: absolute;
  top: 7em;
  /* margin: 0 auto; */
  display: flex;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, 50%);
  font-size: 22px;
}

.main-layout.gate-page {
  background: url(../assets//images/gate-bg.png);
  background-size: 100% 100%;
  min-height: 100%;
}

// .main-layout.upload-selfie-page {
//   background: url(../assets//images/gate-bg.png);
//   background-size: 100% 100%;
//   min-height: 100%;
// }

// .main-layout.completed-page {
//   background: url(../assets//images/completed-bg.png);
//   background-size: 100% 100%;
//   min-height: 100%;
// }
.main-layout::after {
  content: ""; /* Required content property for pseudo-elements */
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../assets/images/bg/Snickers-footer.png) no-repeat bottom left; /* Replace 'your-image.png' with the actual image path */
  background-size: 100%;
  width: 100px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  z-index: 1; /* Ensure the image is on top of other content */
}
