@font-face {
  font-family: Afterglow;
  src: url(../assets/fonts/Afterglow-Regular.ttf);
}
@font-face {
  font-family: DINPro;
  src: url(../assets/fonts/DINPro-Bold_13934.ttf);
}
@font-face {
  font-family: Graphik;
  src: url(../assets/fonts/GraphikSemiboldItalic.otf);
}
@font-face {
  font-family: Myriad Pro;
  font-weight: bold;
  src: url(../assets/fonts/MYRIADPRO-COND.OTF);
}
@font-face {
  font-family: Myriad Pro;
  src: url(../assets/fonts/MYRIADPRO-REGULAR.OTF);
}
@font-face {
  font-family: poppins;
  src: url(../assets/fonts/FMBolyarOrnatePro.otf);
}
@font-face {
  font-family: SpaceGrotesk;
  src: url(../assets/fonts/SpaceGrotesk-VariableFont_wght.ttf);
}
@font-face {
  font-family: conthrax;
  font-weight: bold;
  src: url(../assets/fonts/conthrax-sb.otf);
}
@font-face {
  font-family: gilroy-light;
  src: url(../assets/fonts//Gilroy-Light.otf);
}
@font-face {
  font-family: gilroy-bold;
  src: url(../assets/fonts/Gilroy-ExtraBold.otf);
}
@font-face {
  font-family: marcentra;
  src: url(../assets/fonts/MARSCENTRA-EXTRABOLD.OTF);
}
@font-face {
  font-family: tungsten-bold;
  src: url(../assets/fonts/Tungsten-Bold.otf);
}
@font-face {
  font-family: tungsten-semibold;
  src: url(../assets/fonts/Tungsten-Semibold-1.ttf);
}
@font-face {
  font-family: Tungsten;
  src: url(../assets/fonts/FontsFree-Net-Tungsten-Bold.ttf);
}
// @import "mixins";

@mixin hideScroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0;
  }
}

body {
  margin: 0;
  margin: 0;
  padding: 0;
  // --green: #03a651;
  // background-color: var(--bg-blue);
  font-family: gilroy-bold;
  // background-color: #33146f;
  // background-image: url(../assets/images/bg/BG1.png);
  // background-color: #be0000;
  background: #532a16;
}
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.btn {
  border-radius: 8px;
  outline: none;
  border: none;
  // padding: 12px 30px;
  padding: 10px 45px;
  // font-family: Graphik;
  font-size: 25px;
  // font-style: italic;
  font-weight: 600;
  text-align: center;

  color: #000;
  cursor: pointer;
  transition: all 0.3s;
  &:hover:not(:disabled) {
    transform: scale(1.02);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &.btn-primary {
    // border-radius: 100px;
    // border: 1px solid #000;
    // box-shadow: 0px 10px 8px 0px rgba(0, 0, 0, 0.25);
    // background: #4e2f8b;
    // color: white;
    // // position: relative;
    // // top: 12vh;
    // // margin-bottom: 0.5em;
    // border-width: 3px; /* Adjust the width as needed */
    // border-style: inset;
    // border-color: #fff; /* Set the color of the inner border */
    // .btn.btn-primary {
    border-radius: 10px;
    // border: 1px solid #000;
    box-shadow: 0px 10px 0px 0px #d72c16;
    background: #ffba12;
    // color: white;
    color: #d72c16;
    // border-width: 1px;
    /* border-style: inset; */
    /* border-color: #fff; */
    // box-shadow: 0 0 0 5px #4e2f8b, inset 0 0 0 1px #fff;
    // border: 2px solid #000;
    font-family: tungsten-semibold;

    margin: 0 auto;
  }
}
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 15px;
  user-select: none;
  text-align: start;
  color: #fff;
  font-family: tungsten-semibold;
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          // background-color: white;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border: 1px solid #ea9b9c;
    background: #d88081;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 4px;
      height: 9px;
      border: solid #ed1c24;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.input-group {
  margin-bottom: 20px;
  text-align: center;
  margin: 0 auto 20px;
}
.error {
  color: #ffba12;
  text-align: center;
  font-family: SpaceGrotesk;
  font-size: 16px;
}

input {
  padding: 10px;
  font-size: 18px;
  // text-align: center;
  text-align: left;
  border: none;
  box-sizing: border-box;
  font-family: tungsten-semibold;
  background: transparent;
  // background: #ffff;
  border-radius: 8px;
  border: 1px solid #ffff;
  color: #fff;
  width: 100%;
  outline: none;
  background: #b17e64;
  &::placeholder {
    color: #ffe2be;
    font-family: tungsten-semibold;
  }
}

.grecaptcha-badge {
  bottom: 40px !important;
  display: none;
}
.Toastify__toast-theme--light {
  display: none;
  background: var(--bg-red);
  color: white;
  &.Toastify__toast--success {
    background: var(--bg-blue);
    display: none;
  }
  .Toastify__toast-theme--default {
    display: none;
    /* Additional styling if needed */
  }
}
.App {
  height: 100svh;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  @media screen and (min-width: 768px) {
    display: block;
    margin: 0 auto;
    max-width: 375px;
    @include hideScroll;
  }
}

// .flag {
//   width: 100%;
//   height: 40px;
//   // animation: flagAnim 100s linear infinite;
//   flex-shrink: 0;
//   background-position: bottom;
//   background-repeat: repeat-x;
//   background-size: contain;
//   &.top {
//     background-image: url(../assets/images/anim/Flag-Animation.gif);
//     transform: rotateZ(180deg);
//   }
//   &.bottom {
//     background-image: url(../assets/images/anim/Flag-Animation.gif);
//   }
// }

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  .popup-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
  }
  .popup-modal {
    position: relative;
    z-index: 1;
    background-color: white;
    width: 80%;
    max-width: 400px;
    border-radius: 0.5rem;
    max-height: 85vh;
    overflow: auto;
    .header {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid lightgray;
      position: sticky;
      top: 0;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 20px;
        margin: 0;
      }
    }
    .content {
      padding: 1rem;
    }
    .closeBtn {
      position: absolute;
      top: 10px;
      width: 22px;
      right: 10px;
      margin: auto;
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
    }
  }
}
.dropdown {
  z-index: 5;
  background-color: #b17e64;
  position: absolute;
  width: 73%;
  display: flex;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 20vh;
  min-height: 5vh;
  left: 13%;
  border-radius: 5px;
  -webkit-box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.75);
  color: #fff;

  ul {
    text-align: start;
    padding: 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    width: 100%;
    @media (min-width: 768px) {
      padding: 1rem;
    }

    li {
      list-style-type: none;
      padding: 0.5vh 1vw;
      border-radius: 5px;

      &:hover {
        background-color: #532a16;
      }
    }
    .nf {
      pointer-events: none;
    }
  }
}
