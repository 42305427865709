form {
  // width: 85%;
  // width: 85%;
  // position: absolute;
  // top: 60%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // width: 85%;
  // width: 85%;
  /* position: absolute; */
  /* top: 60%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* align-items: center; */
  display: flex;
  flex: 1 1;
  /* justify-content: center; */
  /* padding: .8rem; */
  text-align: center;
  flex-direction: column;
  // margin-bottom: 7em;
  margin-bottom: 2em;
}
.register-page {
  .register-form,
  .otp-form {
    text-align: center;
    // margin: 2em auto;
    // background-color: #210d48;
    border-radius: 16px;
    padding: 1em 1.5em;
    padding-top: 0;
    width: 90vw;
    max-width: 330px;
    .form-title {
      color: #ffff;
      text-align: center;
      font-family: Tungsten;
      font-size: 48px;
      // font-weight: 600;
      line-height: normal;
      // margin: 0.5em 0;
      // margin-top: 0;
      text-transform: uppercase;
      margin-top: 0.1em;
      margin-bottom: 0.1em;
    }
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .input-group {
      .text {
        margin-right: 10px;
      }
      label:not(:first-child) {
        margin-left: 10px;
      }
    }
    .timer-wrapper {
      margin: 30px auto 10px;
      .timer {
        width: 25px;
        border: 1px solid;
        height: 25px;
        border-radius: 50%;
        font-size: 12px;
        margin-right: 6px;
        color: #fff;
      }
      span {
        color: #fff;
        font-family: gilroy-bold;
        font-size: 12px;
        // font-style: italic;
        font-weight: 600;
        line-height: normal;
        &.link {
          margin-left: 6px;
          cursor: pointer;
          text-decoration-line: underline;
          color: #ffba12;
        }
      }
    }
    .reg-lang {
      .reg-lang-title {
        margin-top: 5px;
        margin-bottom: 5px;
        font-family: Myriad Pro;
      }
    }
    .link {
      color: #f4b747;
    }
  }
}
.terms-link {
  color: #f4b747;
}

/* Add styles for the dropdown container */
.state.dropdown {
  position: absolute;
  z-index: 1;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}

/* Style individual dropdown options */
.state.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.state.dropdown li {
  padding: 8px;
  cursor: pointer;
}

.state.dropdown li:hover {
  background-color: #ddd;
}
.snickers-text {
  font-family: Tungsten;
  font-size: 28px;
  color: #fff;
  // margin-bottom: 0;
  text-align: left;
  text-transform: uppercase;
}
.additionalText {
  border: 1px solid #f3dbbc;
  border-radius: 10px;
  background: linear-gradient(0deg, #b17e64 0%, #b17e64 100%), #fff;
  min-height: 10vh;
  width: 100%;
  padding: 1em;
  color: #fff;
  font-family: tungsten-semibold;
  margin-bottom: 1em;
  font-size: 18px;
  &::placeholder {
    color: #ffe2be;
    font-family: tungsten-semibold;
    text-transform: uppercase;
  }
}
.closed {
  color: #fff;
  font-size: 24px;
  /* margin-top: 0; */
  position: relative;
  top: 50%;
}
.terms {
  color: #fff;
  text-align: left;
  padding: 1em;
  padding-right: 2em;
}
