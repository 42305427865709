.upload-selfie-page {
  // color: #2f0743;
  text-align: center;
  // font-family: poppins;

  /* Define the zoom-in animation */
  @keyframes zoomIn {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }
  .text1 {
    // font-size: 14px;
    // font-style: italic;
    font-weight: 600;
    margin: 20px auto;
    padding-bottom: 3em;
    font-size: 1.2em;
  }
  .text2 {
    font-size: 24px;
    // font-style: italic;
    font-weight: 600;
    margin: 0 auto;
  }
  .note {
    margin-top: 20px;
    width: 90%;
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 0px 8px;
    border: 1px solid #ed1c24;
    box-shadow: -5px 8px 12px 0px rgba(0, 0, 0, 0.2);
    font-family: Myriad Pro;
    .text3 {
      color: #ed1c24;
      font-size: 16px;
      margin: 0 auto;
    }
    .text4 {
      margin: 0 auto;
      color: #ed1c24;
      font-size: 10px;
    }
    .repeatUser_gif {
      width: 5rem;
    }
  }
  .congratulation-text {
    font-size: 1.5em;
    padding: 0 1em 0 1em;
    color: #fff;
  }
  .claim-button {
    border-radius: 10px;
    border: 2px solid #000;
    box-shadow: 0px 10px 8px 0px rgba(0, 0, 0, 0.25);
    background: #ffba12;
    color: #000;
    // border: 2px solid #000;
    font-family: "SpaceGrotesk";
    margin-top: 1em;
    outline: none;
    // border: none;
    // padding: 12px 30px;
    padding: 12px 45px;
    // font-family: Graphik;
    font-size: 24px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
  }
}
