.side-menu {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: all 0s;
  @media screen and (min-width: 768px) {
    display: block;
    margin: 0 auto;
    max-width: 450px;
  }
  &.opened {
    transform: translateX(0);
    .bg {
      opacity: 1;
    }
    .modal {
      transform: translateX(0);
    }
  }
  &.closed {
    transition-delay: 0.5s;
    transform: translateX(-100vw);
    .bg {
      opacity: 0;
    }
    .modal {
      transform: translateX(-100vw);
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    opacity: 1;
    transition: all 0.5s ease-in-out;
    z-index: 1;
    transition: all 0.3s;
  }
  .modal {
    position: absolute;
    top: 0;
    width: 80%;
    max-width: 300px;
    left: 0;
    height: 100%;
    z-index: 2;
    // background-image: url(../../assets/images/bg/BG1.png);
    background-color: #3c1400;
    background-size: cover;
    background-position: center;
    height: 100%;
    overflow: auto;
    transition: all 0.5s;
    .logo {
      width: 50%;
      max-width: 120px;
      margin: 30px auto;
    }
    .options {
      .option {
        padding: 15px;
        color: #ffff;
        text-align: center;
        // font-family: poppins;
        font-size: 16px;
        font-weight: 600;
        // background-image: url(../../assets/images/bg/menu-border.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        cursor: pointer;
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .language-option {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.35rem;
      margin-top: 2rem;
      .title {
        margin: 0 0.8rem;
        color: #fff;
        font-size: 0.9rem;
        font-weight: bold;
      }
      .language-selection {
        padding: 0.2rem;
        background-color: #33146f;
        color: #fff;
        border: 1px solid black;
        border-radius: 0.2rem;
      }
    }
  }
}
