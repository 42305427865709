.contact-us-popup {
  text-align: center;
  .popup-modal {
    .header {
      border: none;
    }
    .content {
      padding-top: 0;
    }
  }
  .text3{
    a{
      text-decoration: none;
    }
  }
}
.terms-popup {
  padding: 10px;
  text-align: center;
}
.howtoredeem-us-popup{
  padding: 10px;
  text-align: center;
ol{
  text-align: start;
  li{
    margin-bottom: .5rem;

    ul{
      margin-bottom: .5rem;
    }
  }
}
}
