.thankyou-text {
  color: #faaa30;
  font-size: 2em;
  font-family: tungsten-semibold;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0;
}
.text12 {
  color: #fff;
  font-family: tungsten-semibold;
  font-weight: 700;
  font-size: 1.1em;
  margin-bottom: 6em;
  margin-left: 2em;
  margin-right: 2em;
}
.yellow-text {
  color: #ffba12;
}
