.payout-option-container {
  display: flex;
  border-radius: 21px;
  background-color: #211846;
  //   background: url("../../assets/images/bg/bg4.png"),
  //     #211846 0% 0% / 100px 100px repeat;
  //   background-blend-mode: overlay, normal;
  //   mix-blend-mode: darken;
  //   backdrop-filter: blur(24.5px);
  margin-bottom: 2em;
  justify-content: space-between;
  padding: 0.5em;
  .payout-option {
    min-width: 5em;
    // height: 4em;
    padding: 1em;
    color: #7849d6;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.64px;
    text-transform: uppercase;
  }

  .payout-option.selected {
    border-radius: 26px;
    background: #fff;
  }
}
.cashback-payout-title {
  color: #fff;
  color: #fff;
  font-size: 1.4em;
  margin-bottom: 1em;
  margin-top: 1em;
  font-weight: bold;
}

.payout-input-container {
  text-align: center;
  margin: 2em auto;
  background-color: #210d48;
  border-radius: 16px;
  padding: 1.5em;
  min-height: 10em;
}
.error {
  color: #ffba12;
  text-align: center;
  font-family: tungsten-semibold;
  font-size: 16px;
}

.gate1,
.gate2 {
  display: inline-block;
}

.gate1 {
  position: absolute;
  bottom: 21vh; /* 80% of the viewport height */
  left: 17vw; /* 10% of the viewport width */
  // transform: translate(0, -50%);
  img {
    width: 33vw;
    transition: transform 1s;
    transform-origin: center left;
  }
}

.gate-container.open .gate1 img {
  transform-origin: center left;
  transform: rotateY(150deg);
}

.gate2 {
  position: absolute;
  bottom: 21vh; /* 80% of the viewport height */
  right: 20vw; /* 10% of the viewport width */
  // transform: translate(0, -50%);
  img {
    width: 33vw;
    transition: transform 1s;
    transform-origin: center right;
  }
}
.gate-container.open .gate2 img {
  transform-origin: center right;
  transform: rotateY(150deg);
}
.gate-container {
  padding-left: 100px;
}
.open-text {
  position: absolute;
  top: 60vh; /* 80% of the viewport height */
  left: 50vw;
  font-size: 1em;
  transform: translate(-50%, 0);
  // border-radius: 10px;
  border: 1px solid #000;
  box-shadow: 0px 10px 8px 0px rgba(0, 0, 0, 0.25);
  background: url(../../assets/images/btn-img.png) no-repeat 100%;
  color: #ffc22d;
  // border-width: 1px;
  /* border-style: inset; */
  /* border-color: #fff; */
  // box-shadow: 0 0 0 5px #4e2f8b, inset 0 0 0 1px #fff;
  // border: 2px solid #000;
  border: none;
  font-family: "poppins";
  padding: 12px 45px;
  width: max-content;
  height: 10vh;
}

@media screen and (min-width: 768px) {
  .gate1 {
    position: absolute;
    left: 64px;
    img {
      width: 123px;
    }
  }

  .gate2 {
    position: absolute;
    right: 75px;
    // transform: translate(0, -50%);
    img {
      width: 123px;
    }
  }
}
